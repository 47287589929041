import http from "./Core/Http";
import {
  ProductsOutput,
  ClientDetailsOutput,
  GetTicketsResponse,
  GetTicketRequest,
  GetTicketResponse,
  ReplyTicketRequest,
  ReplyTicketResponse,
  PersonalDetails,
  CompanyDetails,
  ResidentialDetails,
  CloseTicketRequest,
  CloseTicketResponse,
  OpenTicketRequest,
  OpenTicketResponse,
  GetInvoicesResponse,
  CancelProductRequest,
  CancelProductResponse,
} from "../../interfaces/UserInterfaces";

const GetUserData = async (): Promise<ClientDetailsOutput> => {
  try {
    const response = await http.get("/v2/client/details");

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    return {} as ClientDetailsOutput;
  }
  return {} as ClientDetailsOutput;
};

const UpdatePersonalUserData = async (
  params: PersonalDetails
): Promise<ClientDetailsOutput> => {
  try {
    const response = await http.post("/v2/client/details/personal", params);

    if (response.data) {
      return response.data;
    }
  } catch (error: any) {
    return error.response.data;
  }
  return {} as ClientDetailsOutput;
};

const UpdateCompanyData = async (
  params: CompanyDetails
): Promise<ClientDetailsOutput> => {
  try {
    const response = await http.post("/v2/client/details/business", params);

    if (response.data) {
      return response.data;
    }
  } catch (error: any) {
    return error.response.data;
  }
  return {} as ClientDetailsOutput;
}

const UpdateResidentialData = async (
  params: ResidentialDetails
): Promise<ClientDetailsOutput> => {
  try {
    const response = await http.post("/v2/client/details/residential", params);

    if (response.data) {
      return response.data;
    }
  } catch (error: any) {
    return error.response.data;
  }
  return {} as ClientDetailsOutput;
}

const GetProducts = async (): Promise<ProductsOutput> => {
  try {
    const response = await http.get("/v2/client/products");

    if (response.data) {
      return response.data;
    }
  } catch (error: any) {
    return error.response.data;
  }
  return {} as ProductsOutput;
};

const GetInvoices = async (): Promise<GetInvoicesResponse> => {
  try {
    const response = await http.get("/v2/client/invoices");

    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const GetTickets = async (): Promise<GetTicketsResponse> => {
  try {
    const response = await http.get("/v2/client/tickets");

    return response.data;
  } catch (error: any) {
    return error.data;
  }
};

const GetTicket = async (
  params: GetTicketRequest,
): Promise<GetTicketResponse> => {
  try {
    const response = await http.get("/v2/client/ticket", {
      params: { ticketId: params.ticketId },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.data;
  }
};

const ReplyTicket = async (
  params: ReplyTicketRequest,
): Promise<ReplyTicketResponse> => {
  try {
    const response = await http.post(`/v2/client/ticket?ticketId=${params.ticketId}`, params.message);
    return response.data;
  } catch (error: any) {
    return error.data;
  }
}

const CloseTicket = async (
  params: CloseTicketRequest,
): Promise<CloseTicketResponse> => {
  try {
    const response = await http.post(`/v2/client/ticket/close?ticketId=${params.ticketId}`);
    return response.data;
  } catch (error: any) {
    return error.data;
  }
}

const OpenNewTicket = async (
  params: OpenTicketRequest,
): Promise<OpenTicketResponse> => {
  try {
    const response = await http.put(`/v2/client/ticket?deptid=${params.deptid}&subject=${params.subject}`, params.message);

    return response.data;
  } catch (error: any) {
    return error.data;
  }
};

const CancelProduct = async (
  params: CancelProductRequest,
): Promise<CancelProductResponse> => {
  try {
    const response = await http.post(`/v2/client/products/cancel?serviceid=${params.serviceid}&immediate=${params.immediate}`, params.reason);

    return response.data;
  } catch (error: any) {
    return error.data;
  }
}

export { GetUserData, CancelProduct, UpdatePersonalUserData, UpdateCompanyData, UpdateResidentialData, GetProducts, GetInvoices, GetTickets, GetTicket, ReplyTicket, CloseTicket, OpenNewTicket };
