import { PuffLoader } from "react-spinners";
import Container from "react-bootstrap/Container";

interface SpinnerProps {
  size: "small" | "medium" | "large";
  style?: React.CSSProperties;
}

const sizeMap = {
  small: 30,
  medium: 60,
  large: 100,
};

const Spinner = ({ size, style }: SpinnerProps) => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ marginTop: "30vh", position: "absolute", ...style }}
    >
      <PuffLoader color="#00ADEF" loading size={sizeMap[size]} />
    </Container>
  );
};

export default Spinner;
