import { useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";
import { clientProducts, clientDetails } from "../store";
import { useLocation } from "react-router-dom";
import { GetProducts, GetUserData } from "../components/Api/AccountServices";
import {
  ProductsOutput,
  ClientDetailsOutput,
  ProductsData,
} from "../interfaces/UserInterfaces";

export const UserProvider = ({ children }: any) => {
  const [products, setProducts] = useRecoilState<ProductsOutput>(clientProducts);
  const [userDetails, setUserDetails] = useRecoilState<ClientDetailsOutput>(clientDetails);
  const location = useLocation();

  const fetchProducts = useCallback(async () => {
    try {
      const newProducts = await GetProducts();

      if (!newProducts?.success) return;

      const filteredProducts = newProducts.data.filter(
        (product: ProductsData) => product.status === "Active" || product.status === "Suspended"
      );

      if (JSON.stringify(filteredProducts) !== JSON.stringify(products.data)) {
        setProducts({ ...newProducts, data: filteredProducts });
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, [products, setProducts]);

  const fetchUserDetails = useCallback(async () => {
    try {
      const newUserDetails = await GetUserData();

      if (JSON.stringify(newUserDetails) !== JSON.stringify(userDetails)) {
        setUserDetails(newUserDetails);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }, [userDetails, setUserDetails]);

  useEffect(() => {
    fetchProducts();
    fetchUserDetails();
  }, [location, fetchProducts, fetchUserDetails]);

  return <>{children}</>;
};
