import { atom } from "recoil";

import {
  ProductsOutput,
  ClientDetailsOutput,
} from "./interfaces/UserInterfaces";
import { GetSitePropertiesResponse, SiteData } from "./interfaces/WebsiteInterfaces";
import { PhishingData } from "./interfaces/PhishingInterfaces";

// export const name = atom({
//     key: "",
//     default: ""
// });

// const [filter, setFilter] = useRecoilState(filterState);

export const validSession = atom({
  key: "ng_auth_validSession",
  default: {
    success: false,
    msg: "",
    data: null,
  },
});

export const clientProducts = atom({
  key: "ng_clientarea_clientProducts",
  default: {} as ProductsOutput,
});

export const clientDetails = atom({
  key: "ng_clientarea_clientDetails",
  default: {} as ClientDetailsOutput,
});

export const wafSiteData = atom({
  key: "ng_clientarea_waf_siteData",
  default: {} as SiteData,
});

export const phishingProductData = atom({
  key: "ng_clientarea_phishing_domains",
  default: {
    domains: null,
    campaigns: [],
    campaignInfo: null,
  } as PhishingData,
});
