import { useEffect, useState, useCallback } from "react";
import { useRecoilState } from "recoil";
import { clientProducts, phishingProductData } from "../store";
import { useLocation, useSearchParams } from "react-router-dom";
import { ProductsOutput, ProductsData } from "../interfaces/UserInterfaces.js";
import {
  PhishingData,
  DomainData,
  CampaignInfo,
  CampaignRecipientData,
  CampaignWebsiteData,
  CampaignData,
  CampaignEventData,
} from "../interfaces/PhishingInterfaces";
import {
  GetDomains,
  GetCampaigns,
  GetRecipients,
  GetWebsite,
  GetEvents,
} from "../components/Api/PhishingServices";
import { cloneDeep } from "lodash";

export const fetchAndUpdatePhishingData = async (
  phishingData: PhishingData,          
  setPhishingData: (value: PhishingData) => void  
) => {
  try {
    const domains = await GetDomains();
    const campaigns = await GetCampaigns();
    const recipients = await GetRecipients({ campaignId: Number(phishingData.campaignInfo?.id) });

    let campaignInfo = cloneDeep(phishingData.campaignInfo);
    if (campaignInfo != null) {
      campaignInfo.recipients = recipients.data;
    }

    setPhishingData({
      ...phishingData,
      domains: domains,
      campaigns: campaigns,
      campaignInfo,
    });

    return { domains, campaigns };
  } catch (error) {
    console.error("Error in fetchAndUpdatePhishingData:", error);
    throw error; // rethrow the error if you need to handle it further up
  }
};

export const PhishingProvider = ({ children }: any) => {
  const [products] = useRecoilState(clientProducts);
  const [phishingData, setPhishingData] = useRecoilState(phishingProductData);  // Use hooks here, where it's valid
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const getProduct = useCallback(() => {
    if (products.success && products.data) {
      return products.data.filter(product => Number(product.pid) === 5) || [];
    }
    return [];
  }, [products]);

  const fetchData = useCallback(async () => {
    if (loading) return; // Prevent double loading
    setLoading(true);

    if (location.pathname.includes("/phishing")) {
      const product = getProduct();
      if (product.length === 1) {
        let { domains, campaigns } = await fetchAndUpdatePhishingData(phishingData, setPhishingData);
        
        if (location.pathname.includes("/manage") || location.pathname.includes("/wizard")) {
          const campaignId = searchParams.get("id");
          if (campaignId) {
            const campaign = campaigns.find(c => c.id === Number(campaignId));
            if (campaign) {
              const recipients = await GetRecipients({ campaignId: Number(campaignId) });
              const website = await GetWebsite({ campaignId: Number(campaignId) });
              const events = await GetEvents({ campaignId: Number(campaignId) });
              const campaignInfo: CampaignInfo = {
                ...campaign,
                recipients: recipients.data,
                events: events,
                website: website.data,
              };
              setPhishingData({
                domains: domains,
                campaigns: campaigns,
                campaignInfo: campaignInfo,
              });
            }
          }
        }
      }
    }

    setLoading(false);
  }, [products, location, searchParams, getProduct]);

  useEffect(() => {
    fetchData();
  }, [products, location]);

  return <>{children}</>;
};