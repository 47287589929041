import {
  DomainResponse,
  CampaignResponse,
  CampaignAddRequest,
  CampaignAddResponse,
  CampaignDeleteRequest,
  CampaignRecipientsRequest,
  CampaignRecipientsResponse,
  CampaignEventRequest,
  CampaignWebsiteRequest,
  CampaignWebsiteResponse,
  CampaignWebsiteElementsRequest,
  CampaignRecipientsAddRequest,
  CampaignRecipientsAddResponse,
  CampaignEventResponse,
  CampaignEmailUploadRequest,
  CampaignEmailUploadResponse,
  CampaignWebsiteUploadRequest,
  CampaignWebsiteUploadResponse,
  DomainAddRequest,
  DomainAddResponse,
  DomainDeleteRequest,
  DomainData,
  CampaignData,
  CampaignEventData,
  CampaignTestEmailRequest,
  CampaignLaunchRequest,
} from "../../interfaces/PhishingInterfaces";
import http, { get, post, put } from "./Core/Http";

const GetDomains = async (): Promise<DomainData[]> => {
    const response = await get("/v2/phishing/domains");
    if(response == null) return [];
    return response;
};

const GetCampaigns = async (): Promise<CampaignData[]> => {
  const response = await get("/v2/phishing/campaigns");
  if(response == null) return [];
  return response;
};

const GetRecipients = async (
  params: CampaignRecipientsRequest,
): Promise<CampaignRecipientsResponse> => {
  try {
    const response = await http.get("/v2/phishing/campaigns/recipients", {
      params: { campaignId: params.campaignId },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const GetEvents = async (
  params: CampaignEventRequest,
): Promise<CampaignEventData[]> => {

  const response = await get("/v2/phishing/campaigns/events?campaignId="+params.campaignId);
    if(response == null) return [];
    return response;
};

const GetWebsite = async (
  params: CampaignWebsiteRequest,
): Promise<CampaignWebsiteResponse> => {
  try {
    const response = await http.get("/v2/phishing/campaigns/website", {
      params: { campaignId: params.campaignId },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

//TODO: Add CampaignWebsiteElementsResponse (once API is fixed)
const GetWebsiteElements = async (
  params: CampaignWebsiteElementsRequest,
): Promise<any> => {
  try {
    const response = await http.get(
      "/v2/phishing/campaigns/website/elements?websiteId=" + params.websiteId,
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const AddDomain = async (
  params: DomainAddRequest,
): Promise<DomainAddResponse> => {
  try {
    const response = await http.put("/v2/phishing/domains/private?domain="+params.name);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const AddCampaign = async (
  params: CampaignAddRequest,
): Promise<CampaignAddResponse> => {
  try {
    const response = await http.put("/v2/phishing/campaigns", params);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const AddRecipients = async (
  params: CampaignRecipientsAddRequest,
): Promise<boolean> => {
    const response = await put("/v2/phishing/campaigns/recipients?campaignId=" + params.id, params.emails);
    console.log(response)
    return response != null;
};

const DeleteCampaign = async (params: CampaignDeleteRequest): Promise<any> => {
  try {
    const response = await http.delete("/v2/phishing/campaigns", {
      params: { id: params.campaignId },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const DeleteDomain = async (params: DomainDeleteRequest): Promise<any> => {
  try {
    const response = await http.delete("/v2/phishing/domains/private", {
      params: { id: params.domainId },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const UploadEmail = async (
  params: CampaignEmailUploadRequest,
): Promise<CampaignEmailUploadResponse> => {
  try {
    const response = await http.put("/v2/phishing/campaigns/email/upload?campaignId=" + params.id, {content: btoa(params.code), subject: params.subject});
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const UploadWebsite = async (
  params: CampaignWebsiteUploadRequest,
): Promise<CampaignWebsiteUploadResponse> => {
  try {
    const response = await http.put("/v2/phishing/campaigns/website/upload?campaignId=" + params.id, {content: btoa(params.code)});
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const GetWebsiteCode = async (
  params: CampaignEventRequest,
): Promise<CampaignEventResponse> => {
  try {
    const response = await http.get(
      "/v2/phishing/campaigns/website/download?campaignId=" + params.campaignId,
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const GetEmailCode = async (
  params: CampaignEventRequest,
): Promise<CampaignEventResponse> => {
  try {
    const response = await http.get(
      "/v2/phishing/campaigns/email/download?campaignId=" + params.campaignId,
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

const SendTestEmail = async (
  params: CampaignTestEmailRequest,
): Promise<boolean> => {
    const response = await post("/v2/phishing/campaigns/email/test?campaignId=" + params.campaignId, {email:params.email});
    return response != null;
};

const LaunchCampaign = async (
  params: CampaignLaunchRequest,
): Promise<boolean> => {
    const response = await post("/v2/phishing/campaigns/email/send?campaignId=" + params.campaignId);
    return response != null;
};


export {
  GetDomains,
  GetCampaigns,
  GetRecipients,
  GetEvents,
  GetWebsite,
  GetWebsiteElements,
  AddCampaign,
  DeleteCampaign,
  AddRecipients,
  UploadEmail,
  UploadWebsite,
  GetWebsiteCode,
  GetEmailCode,
  AddDomain,
  DeleteDomain,
  SendTestEmail,
  LaunchCampaign
};
