import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { clientProducts, wafSiteData } from "../store";
import { useLocation, useSearchParams } from "react-router-dom";
import { ProductsOutput, ProductsData } from "../interfaces/UserInterfaces.js";
import { GetSiteProperties } from "../components/Api/WebsiteServices";
import { GetSitePropertiesResponse, SiteData } from "../interfaces/WebsiteInterfaces";

export const getNewestSiteProperties = async (domain: string) => {
  return await GetSiteProperties(domain);
}

export const fetchAndUpdateSiteProperties = async (
  domain: string,
  setSiteData: (value: SiteData) => void,
  products: ProductsOutput
) => {
  const newProperties = await GetSiteProperties(domain);
  if (products.success) {
    if (products.data) {
      const result = products.data.filter((product) => {
        return (
          product.domain === domain &&
          product.groupname === "Web Application Firewall" &&
          product.status === "Active"
        );
      });
      if (result) {
        newProperties.product = result[0];
      }
    }
  }
  if(!newProperties.product) {
    newProperties.product = {} as ProductsData;
  }
  setSiteData(newProperties);
};

export const WAFProvider = ({ children }: any) => {
  const [products] = useRecoilState<ProductsOutput>(clientProducts);
  const [siteProperties, setSiteProperties] =
    useRecoilState<SiteData>(wafSiteData);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const getProduct = (id: number): ProductsData[] => {
    if (products.success) {
      if (products.data) {
        const result = products.data.filter((product) => {
          return (
            Number(product.id) === Number(id) &&
            product.groupname === "Web Application Firewall" &&
            product.status === "Active"
          );
        });
        if (result) {
          return result;
        }
      }
    }
    return [] as ProductsData[];
  };

  const fetchSiteData = async (domain: string, product: ProductsData) => {
    if (domain) {
      let properties = await GetSiteProperties(domain);
      if (JSON.stringify(properties) !==  JSON.stringify(siteProperties)) {
        console.log(properties)
        properties.product = product;
        setSiteProperties(properties);
      }
    }
    return {} as SiteData;
  };

  useEffect(() => {
    if (location.pathname.includes("/websites/")) {
      if (searchParams.get("id")) {
        const product = getProduct(Number(searchParams.get("id")));
        if (product.length === 1) {
          const domain = product[0].domain;
          fetchSiteData(domain, product[0]);
        }
      }
    }
  }, [location, searchParams, products]);

  return <>{children}</>;
};
