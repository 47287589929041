import { createContext, useContext, useEffect, useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { validSession } from "../store";
import ValidateSession from "../components/Api/Auth/ValidateSession";
import { useLocation } from "react-router-dom";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
  const [session, setSession] = useRecoilState(validSession);
  const location = useLocation();

  const checkLogin = useCallback(async () => {
    try {
      const sessionValid = await ValidateSession();

      if (!sessionValid?.success) {
        window.location.href =
          "https://api.my.netguard.solutions/api/v1/authflow/oauth/signin";
        return;
      }

      if (JSON.stringify(sessionValid) !== JSON.stringify(session)) {
        setSession(sessionValid);
      }
    } catch (error) {
      console.error("Error validating session:", error);
    }
  }, [session, setSession]);

  useEffect(() => {
    checkLogin();
  }, [location, checkLogin]);

  const authContextValue = useMemo(() => ({ ...session }), [session]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
