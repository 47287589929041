import http, { get } from "./Core/Http";

import {
  GetSitePropertiesResponse,
  SiteFirewallProfile,
  SSLCertificateResponse,
  SetFilterProfileDataRequest,
  SetWebsiteBackendsRequest,
  SetWebsiteConfigDataRequest,
  countryData,
  topTalker,
  RecommendationResponse,
  SiteCacheProfile,
  IncidentResponse,
  Request,
  Stats,
  ErrorResponse,
  SiteData
} from "../../interfaces/WebsiteInterfaces";

const GetSiteProperties = async (
  domain?: string,
): Promise<SiteData> => {
  try {
    let url = domain ? "/v2/waf/properties?domain=" + domain : "/v2/waf/properties";
    const response = await get(url);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

const ChangeProfileStatus = async (
  domain: string,
  profileName: string,
  status: boolean,
): Promise<any> => {
  try {
    const response = await http.post(
      `/v2/waf/profile/${status ? "enable" : "disable"}?domain=${domain}&name=${profileName}`,
      {},
    );
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const ClearWebsiteCache = async (domain: string): Promise<any> => {
  console.log('domain', domain);
  try {
    const response = await http.delete(`/v2/waf/cache/purge?domain=${domain}`, {});
    console.log('response', response.data);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const DeleteCacheProfile = async (domain: string, profileName: string): Promise<any> => {
  try {
    const response = await http.delete(`/v2/waf/cache?domain=${domain}&name=${profileName}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

const SetCacheProfile = async (domain: string, profileName: string, profileData: SiteCacheProfile): Promise<any> => {
  try {
    const response = await http.post(
      `/v2/waf/cache?domain=${domain}&name=${profileName}`,
      profileData
    );
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

const SetWebsiteCache = async (
  domain: string,
  setting: boolean,
): Promise<any> => {
  try {
    const response = await http.post(
      `/v2/waf/config/cache?domain=${domain}&enable=${setting}`,
      {},
    );
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const SetWebsiteBackends = async (
  params: SetWebsiteBackendsRequest
): Promise<any> => {
  try {
    const response = await http.post(
      `/v2/waf/config/backend?domain=${params.domain}&backends=${params.backends}&loadbalancing=${params.loadbalancing}&scheme=${params.scheme}`,
      {},
    );

    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const SetFilterProfileData = async (
  params: SetFilterProfileDataRequest
): Promise<any> => {
  try {
    const response = await http.post(
      `/v2/waf/profile?domain=${params.domain}&name=${params.profileName}`,
      params.profileRules,
    );

    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const SetWebsiteConfigData = async (
  params: SetWebsiteConfigDataRequest
): Promise<any> => {
  try {
    const response = await http.post(
      `/v2/waf/config?domain=${params.domain}`,
      params.siteConfig,
    );
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const CreateFilterProfile = async (
  domain: string,
  profileName: string,
  profileRules: SiteFirewallProfile,
): Promise<any> => {
  try {
    const response = await http.put(
      `/v2/waf/profile?domain=${domain}&name=${profileName}`,
      profileRules,
    );
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const DeleteFilterProfile = async (
  domain: string,
  profileName: string,
): Promise<any> => {
  try {
    const response = await http.delete(
      `/v2/waf/profile?domain=${domain}&name=${profileName}`,
    );
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const GetSSLCertificate = async (
  domain: string,
): Promise<SSLCertificateResponse> => {
  try {
    const response = await http.get("/v2/waf/certificate?domain=" + domain);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const SetSSLCertificate = async (
  domain: string,
  certificate: string,
  key: string,
): Promise<any> => {
  try {
    const response = await http.post(`/v2/waf/certificate?domain=${domain}`, {
      certificate: btoa(certificate),
      key: btoa(key)
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const GenerateSSLCertificate = async (domain: string): Promise<any> => {
  try {
    const response = await http.post(
      "/v2/waf/certificate/generate?domain=" + domain,
    );
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const GetWebsiteTrafficGraphData = async (domain: string): Promise<any> => {
  try {
    const response = await http.get("/v2/monitoring/graph/rps?domain=" + domain);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const GetWebsiteTrafficPerCountry = async (domain: string): Promise<countryData[]> => {
  try {
    const response = await http.get("/v2/monitoring/map/rpc?domain=" + domain);
    return response.data.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const GetWebsiteLatestRequests = async (domain: string): Promise<Request[]> => {
  try {
    const response = await http.get("/v2/waf/requests?domain=" + domain);
    return response.data.data;
  } catch (error: any) {
    return error.response.data;
  }
}

const GetWebsiteStats = async (domain: string): Promise<Stats> => {
  try {
    const response = await http.get("/v2/waf/stats?domain=" + domain);
    return response.data.data;
  } catch (error: any) {
    return error.response.data;
  }
}

const GetWebsiteTrafficTopTalkers = async (domain: string): Promise<topTalker[]> => {
  try {
    const response = await http.get("/v2/monitoring/toptalkers?domain=" + domain);
    return response.data.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const GetRecommendations = async (domain?: string): Promise<RecommendationResponse> => {
  try {
    let url = domain ? "/v2/waf/recommendations?domain=" + domain : "/v2/waf/recommendations";
    const response = await http.get(url);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

const GetIncidents = async (domain?: string): Promise<IncidentResponse> => {
  try {
    let url = domain ? "/v2/waf/incidents?domain=" + domain : "/v2/waf/incidents";
    const response = await http.get(url);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

const CreateCacheProfile = async (domain: string, profileName: string, profileData: SiteCacheProfile) => {
  try {
    const response = await http.put(
      `/v2/waf/cache?domain=${domain}&name=${profileName}`,
      profileData
    );
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

const GetWebhookInfo = async (domain: string) => {
  try {
    const response = await http.get(`/v2/waf/notification/webhook?domain=${domain}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

const SetWebhookURL = async (domain: string, url: string) => {
  try {
    const response = await http.post(`/v2/waf/notification/webhook?domain=${domain}`, url.toString());
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

const TestWebhook = async (domain: string) => {
  try {
    const response = await http.post(`/v2/waf/notification/webhook/test?domain=${domain}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export {
  GetSiteProperties,
  ChangeProfileStatus,
  CreateFilterProfile,
  ClearWebsiteCache,
  SetWebsiteCache,
  CreateCacheProfile,
  SetWebsiteBackends,
  SetFilterProfileData,
  GetSSLCertificate,
  SetSSLCertificate,
  GenerateSSLCertificate,
  GetWebsiteTrafficGraphData,
  DeleteFilterProfile,
  SetWebsiteConfigData,
  GetWebsiteTrafficPerCountry,
  GetWebsiteTrafficTopTalkers,
  GetWebsiteLatestRequests,
  GetRecommendations,
  DeleteCacheProfile,
  SetCacheProfile,
  GetWebhookInfo,
  SetWebhookURL,
  GetIncidents,
  GetWebsiteStats,
  TestWebhook
};
