import http from "../Core/Http";

const ValidateSession = async (): Promise<any> => {
  try {
    const response = await http.get("/v1/authflow/validate");

    return response.data;
  } catch (error) {
    return {};
  }
};

export default ValidateSession;
