import { NotificationManager } from "react-notifications";
import { ErrorResponse } from "../../interfaces/WebsiteInterfaces";

const isError = (value: ErrorResponse): value is ErrorResponse => {
    return value.msg !== undefined;
};

class LogProvider {
    static info(title: string, message: string) {
        NotificationManager.info(message, title);
    }

    static success(title: string, message: string) {
        NotificationManager.success(message, title);
    }

    static warning(title: string, message: string) {
        NotificationManager.warning(message, title);
    }

    static error(title: string, message: string) {
        NotificationManager.error(message, title);
    }

    static log(title: string, data: any, type: "info" | "success" | "warning" | "error") {
        if (isError(data)) {
            if (data.success) {
                LogProvider.success(title, data.msg);
            } else {
                LogProvider.error(title, data.msg);
            }
            return;
        }

        if(typeof data === "object") {
            data = data.toString();

            if(data.startsWith("Error: ")) {
                data = data.replace("Error: ", "");
            }
        }

        if (typeof data === "string") {
            switch (type) {
                case "info":
                    LogProvider.info(title, data);
                    break;
                case "success":
                    LogProvider.success(title, data);
                    break;
                case "warning":
                    LogProvider.warning(title, data);
                    break;
                case "error":
                    LogProvider.error(title, data);
                    break;
            }
        }
    }
}

export default LogProvider;