import axios, { AxiosInstance } from "axios";
import LogProvider from "../../Providers/LogProvider";

interface ApiResponse {
  success: boolean;
  msg: string;
  data: any;
}

const http: AxiosInstance = axios.create({
  baseURL: "https://api.my.netguard.solutions/api/",
  timeout: 15000,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default http;

export const get = async (uri: string): Promise<any> => {
  try {
    let response = await http.get(uri);
    if(response == null || response.data == null || response.data == "") throw new Error("Empty response");
    return handleApiResponse(response.data);
  } catch(ex: any) {
    console.log(typeof ex == "object", ex.response != null, ex.response.data != null, ex.response.data.success == "false")
    if(typeof ex == "object" && ex.response != null && ex.response.data != null && !ex.response.data.success) {
      console.log(ex);
      LogProvider.error("Error", ex.response.data.msg);
    } else {
      LogProvider.error("Error", "Unknown error while fetching data.");
    }
  }
}

export const post = async (uri: string, body: {[key: string]: any} = {}): Promise<any> => {
  try {
    let response = await http.post(uri, body);
    if(response == null || response.data == null || response.data == "") throw new Error("Empty response");
    return handleApiResponse(response.data);
  } catch(ex: any) {
    console.log(ex);
    console.log(typeof ex == "object", ex.response != null, ex.response.data != null, ex.response.data.success == "false")
    if(typeof ex == "object" && ex.response != null && ex.response.data != null && !ex.response.data.success) {
      console.log(ex);
      LogProvider.error("Error", ex.response.data.msg);
    } else {
      LogProvider.error("Error", "Unknown error while fetching data.");
    }
  }
}

export const put = async (uri: string, body: {[key: string]: any} = {}): Promise<any> => {
  try {
    let response = await http.put(uri, body);
    if(response == null || response.data == null || response.data == "") throw new Error("Empty response");
    return handleApiResponse(response.data);
  } catch(ex: any) {
    if(typeof ex == "object" && ex.response != null && ex.response.data != null && !ex.response.data.success) {
      console.log(ex);
      LogProvider.error("Error", ex.response.data.msg);
    } else {
      LogProvider.error("Error", "Unknown error while fetching data.");
    }
  }
}

export const del = async (uri: string): Promise<any> => {
  try {
    let response = await http.delete(uri);
    if(response == null || response.data == null || response.data == "") throw new Error("Empty response");
    return handleApiResponse(response.data);
  } catch(ex: any) {
    if(typeof ex == "object" && ex.response != null && ex.response.data != null && !ex.response.data.success) {
      console.log(ex);
      LogProvider.error("Error", ex.response.data.msg);
    } else {
      LogProvider.error("Error", "Unknown error while fetching data.");
    }
  }
}

const handleApiResponse = (response: any): any => {
  if (typeof response === "string") {
      response = JSON.parse(response);
  }

  let data: ApiResponse = response;

  if(!data.success) {
    LogProvider.error("Error", data.msg);
    return null;
  }
  return data.data || "no data";
}

export function httpErrorToHuman(error: any): string {
  if (error.response && error.response.data) {
    let { data } = error.response;

    if (typeof data === "string") {
      try {
        data = JSON.parse(data);
      } catch (e) {
        // do nothing, bad json
      }
    }

    if (data.errors && data.errors[0] && data.errors[0].detail) {
      return data.errors[0].detail;
    }
  }

  return error.message;
}
