import { NotificationContainer } from "react-notifications";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/NetGuard.css";
import "./assets/css/NetGuardResponsive.css";
import "react-notifications/lib/notifications.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import Spinner from "./components/Elements/Spinner";
import { AuthProvider } from "./providers/AuthProvider";
import { UserProvider } from "./providers/UserProvider";
import { WAFProvider } from "./providers/WAFProvider";
import { PhishingProvider } from "./providers/PhishingProvider";

const ClientAreaRouter = lazy(() => import("./routers/ClientAreaRouter"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Spinner size="large" />}>
          <AuthProvider>
            <UserProvider>
              <WAFProvider>
                <PhishingProvider>
                  <Routes>
                    <Route
                      path="/*"
                      element={<Navigate to={"/client/dashboard"} />}
                    />
                    <Route path="/client/*" element={<ClientAreaRouter />} />
                    <Route
                      path="/client"
                      element={<Navigate to={"/client/dashboard"} />}
                    />
                  </Routes>
                </PhishingProvider>
              </WAFProvider>
            </UserProvider>
          </AuthProvider>
        </Suspense>
      </BrowserRouter>
      <NotificationContainer />
    </>
  );
}

export default App;
